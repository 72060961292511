#container-circles {
  bottom: 270px !important;
}

#inner-circle {
  background-image: url('../../../icons/svg/camera.svg');
  top: 50px;
  height: 70px;
  width: 70px;
  margin: -35px 0 0 -35px;
}

#outer-circle {
  left: -50px;
  height: 100px;
  width: 100px;
}
